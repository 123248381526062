import { runInAction, toJS, reaction, action, autorun, observable, makeObservable } from "mobx";
import { apiRequest } from "../utils/api";
import globals from "../globals";
import usuariosStore from "./UsuariosStore";

class CarritoStore {
    @observable showCarritoModal = false;
    @observable carrito = [];
    @observable comentario = '';

    constructor() {
        const carrito = localStorage.getItem('carrito');
        try {
            this.carrito = JSON.parse(carrito);
        } catch(e) {
            this.carrito = [];
        }
        if (!this.carrito)
            this.carrito = [];
        makeObservable(this);
    }

    persistData() {
        //console.log('persistData', toJS(this.carrito));
        localStorage.setItem('carrito', JSON.stringify(toJS(this.carrito)));
    }

    clean() {
        this.carrito = [];
        this.comentario = '';
        this.persistData();
    }

    // Devuelve la cantidad de productos distintos
    cant() {
        return this.carrito.length;
    }

    // Devuelve la cantidad de productos
    cantTotal() {
        return this.carrito.reduce((a, b) => a + b.quantity, 0);
    }

    total() {
        return this.carrito.reduce((a, p) => a + (p.quantity * p.price), 0);
    }

    @action
    setComentario(txt) {
        this.comentario = txt;
    }

    @action
    setCantIdx(cant, idx) {
        this.carrito[idx].quantity = parseInt(cant);
        this.persistData();
    }

    @action
    async enviar() {
        const url = '/venta';
        const submit = {
            carrito: this.carrito,
            servicios: [], // Para gastos de envio
            comentario: this.comentario,
            retiro: 'delivery' // No implementado en districentro
        }
        /* Para cuando haya tipos de delivery
        if (this.isDelivery && this.deliveryCost)
            submit['servicios'] = [ { cant: 1, concepto: 'Costo de envio', precio: this.deliveryCost } ]
         */
        try {
            const ret = await apiRequest(url, submit);
            this.clean();
            return [false, ret.id];
        } catch(e) {
            console.error(e);
            try {
                return [true, JSON.parse(e).msg];
            } catch(e) {
                console.error('error json', e);
                return [true, "Error generando la orden."];
            }
        }
    }

    @action
    borrarIdx(idx) {
        this.carrito.splice(idx, 1);
        this.persistData();
    }

    @action
    modal(value) {
        if (value !== undefined) {
            this.showCarritoModal = value;
        } else {
             this.showCarritoModal = !this.showCarritoModal;
        }
    }

    @action
    agregar(producto, cant) {
        cant = parseInt(cant);
        const prod = this.carrito.filter(p => p.id == producto.id);
        if (prod.length > 0) {
            const idx = this.carrito.findIndex(p => p.id == producto.id);
            this.carrito[idx].quantity += cant;
        } else {
            this.carrito.push({...producto, quantity: cant});
        }
        this.persistData();
    }
}
const carritoStore = new CarritoStore();
export default carritoStore;