import { useState } from "react";
import { observer } from "mobx-react";
import {Carousel} from "react-bootstrap";
import {useParams} from "react-router-dom";
import CategoriasBreadcrumbs from "./CategoriasBreadcrumbs";
import productosStore from "../stores/ProductosStore";
import {currencyFormat} from "../utils/utils";
import {cdnURL} from "../utils/api";
import usuariosStore from "../stores/UsuariosStore";
import carritoStore from "../stores/CarritoStore";
import { ShowCarrito } from "./Carrito";
import globals from "../globals";

function ProductoDescripcion() {
    const [cant, setCant] = useState(1);
    const { prodId, prodName } = useParams();
    const fotos = [];
    if (productosStore.cargando) {
        return (
            <div className="row cargando-categorias text-center producto-detalle">
                <div className="col-12 px-0">
                    <p><i className='fa-solid fa-spinner'/> Cargando {prodName}... </p>
                </div>
            </div>
        );
    }
    if (!productosStore.producto || productosStore.producto.id.toString() !== prodId.toString())
        productosStore.prodById(prodId);
    const prod = productosStore.producto;
    if (!prod) {
        return (
            <div className="row cargando-categorias text-center producto-detalle">
                <div className="col-12 px-0">
                    <p><i className='fa-solid fa-spinner'/> Cargando {prodName}... </p>
                </div>
            </div>
        );
    }
    if (prod.image1) fotos.push(prod.image1);
    if (prod.image2) fotos.push(prod.image2);
    if (prod.image3) fotos.push(prod.image3);
    if (fotos.length === 0)
        fotos.push(undefined)
    return (
        <div className="container my-5 producto-detalle">
            <div className="row d-flex justify-content-center">
                <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12">
                    <CategoriasBreadcrumbs noTodos volver familia={prod.family_name}/>
                </div>
            </div>

            <div className="row d-flex justify-content-center detalle-producto">
                <div className="col-xl-5 col-lg-8 col-md-6 col-sm-12 col-12">
                    <Carousel interval={2500}>
                        {fotos.length > 0 &&
                            <Carousel.Item>
                                <img alt='' src={cdnURL(fotos[0], '/img/producto.png')} className="d-block h-max-prod" />
                            </Carousel.Item>
                        }
                        {fotos.length > 1 &&
                            <Carousel.Item>
                                <img alt='' src={cdnURL(fotos[1], '/img/producto.png')} className="d-block h-max-prod" />
                            </Carousel.Item>
                        }
                        {fotos.length > 2 &&
                            <Carousel.Item>
                                <img alt='' src={cdnURL(fotos[2], '/img/producto.png')} className="d-block h-max-prod" />
                            </Carousel.Item>
                        }
                    </Carousel>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                    <div className="d-flex flex-column info-producto h-100">

                        <h3 className="nombre-producto">{prod.name}</h3>
                        { prod.sale_price_pes >= 0 &&
                            <h5 className="precio">{currencyFormat(prod.sale_price_pes)}</h5>
                        }

                        <p className="descripcion mt-4">
                            {prod.original_product_id &&
                                <strong>
                                    {prod.original_product_id}
                                    <br />
                                </strong>
                            }
                            {prod.brand &&
                                <>
                                <strong> MARCA: </strong> {prod.brand}<br />
                                </>
                            }
                        </p>
                        <p className="descripcion mt-4">{prod.description}</p>

                        {usuariosStore.logged && !globals.soloCatalogo &&
                            <div className={'row'}>
                                <div className={'col-3'}>
                                    <input
                                        className={'agregar-carrito-cant'}
                                        type={'number'}
                                        step={1}
                                        min={1}
                                        value={cant}
                                        onChange={e => setCant(e.target.value)}
                                    />
                                </div>
                                <div className={'col-9'}>
                                    <button className={'agregar-carrito'}
                                            onClick={() => {
                                                carritoStore.agregar(
                                                    {
                                                        id: prod.id,
                                                        name: prod.name,
                                                        prod_id: prod.original_product_id,
                                                        brand: prod.brand,
                                                        price: prod.sale_price_pes
                                                    }, cant);
                                                ShowCarrito();
                                            }}
                                    >Agregar al Carrito</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default observer(ProductoDescripcion);